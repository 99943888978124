<template>
  <div class="MERCH">
    <div class="merch-card">
      <div class="message-box scorr-roll">
       
      </div>
      <div class="btn-box">
        <div class="pass" @click="passMessage">通过</div>
        <div class="refuse" @click="refuseMessage">拒绝</div>
      </div>
    </div>
  </div>
</template>

<script>

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Goods");
export default {
  name: "SupertubeBasicmessage",

  data() {
    return {
      
    };
  },
  components: {
    GoodsBasic,
    GoodsIfy,
    SpecificationBook,
  },
  created() {
    //console.log(123);
    this.GetMessage();
  },
  methods: {
    ...mapActions(["getplatformGoodsdetail", "postplatformGoodsedit"]),
    // 获取详细信息
    async GetMessage() {
      let data = await this.getplatformGoodsdetail({
        id: this.$route.query.id,
      });
      //console.log(data);
      this.goodsZZData = data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .message-box {
      width: 100%;
      max-height: 75vh;
      padding-top: 10px;
    }
  }
  .btn-box {
    cursor: pointer;
    margin-top: 1%;
    width: 100%;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    .pass {
      margin-right: 1%;
      width: 100px;
      height: 100%;
      background: #00bfbf;
      text-align: center;
      line-height: 40px;
      color: #ffffff;
      font-size: 13px;
      font-weight: 700;
      border-radius: 4px;
    }
    .refuse {
      width: 100px;
      height: 100%;
      background: #d7d7d7;
      text-align: center;
      line-height: 40px;
      color: black;
      font-size: 13px;
      font-weight: 700;
      border-radius: 4px;
    }
  }
}
</style>
